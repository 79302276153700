<template>
  <div v-if="filterForm">
    <div class="field">
      <label for="filterBy">Filtrar por</label>
      <select
        id="filterBy"
        class="tui tuim ui search dropdown"
        placeholder="Escolha o tipo de dado"
        v-model="filterBy"
      >
        <option value>Escolha o tipo de dado</option>
        <option
          v-for="(item, index) in filters"
          :key="index"
          :value="item.key"
          v-text="item.text"
        />
      </select>
    </div>
    <div v-show="filterBy === 'period'">
      <div class="field" :class="{ 'error' : errorDate(filterForm.fieldOne) }">
        <the-mask
          v-if="filterBy === 'period'"
          :mask="['##/##/####']"
          v-model="filterForm.fieldOne"
          :masked="true"
          placeholder="Período Inicial" />
      </div>
      <div class="field" :class="{ 'error' : errorDate(filterForm.fieldTwo) }">
        <the-mask
          v-if="filterBy === 'period'"
          :mask="['##/##/####']"
          v-model="filterForm.fieldTwo"
          :masked="true"
          placeholder="Período Final" />
      </div>
    </div>
    <div v-show="filterBy === 'payment_method'" class="field">
      <select
        class="tui tuim ui search dropdown"
        placeholder="Escolha o tipo de dado"
        v-model="paymentMethod"
      >
        <option value>Método de pagamento</option>
        <option
          v-for="(item, index) in paymentMethods"
          :key="index"
          :value="item.key"
          v-text="item.text"
        />
      </select>
    </div>
    <div v-show="filterBy === 'status'" class="field">
      <select
        class="tui tuim ui search dropdown"
        placeholder="Escolha o tipo de dado"
        v-model="transactionStatus"
      >
        <option value>Status</option>
        <option
          v-for="(item, index) in statuses"
          :key="index"
          :value="item.key"
          v-text="item.text"
        />
      </select>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { checkDate } from '@/helpers'

export default {
  name: 'TFilesSingleFilters',
  data () {
    return {
      filters: [
        { key: 'period', text: 'Período' },
        { key: 'payment_method', text: 'Forma de Pagamento' },
        { key: 'status', text: 'Status' },
      ],
      paymentMethods: [
        { key: 'bank_slip', text: 'Boleto' },
        { key: 'credit_card', text: 'Cartão de Crédito' },
        { key: 'debit_account', text: 'Débito' },
      ],
      statuses: [
        { key: 'pending', text: 'Pendente' },
        { key: 'generated', text: 'Gerado' },
        { key: 'paid', text: 'Pago' },
        { key: 'over_paid', text: 'Pago a mais' },
        { key: 'under_paid', text: 'Pago a menos' },
        { key: 'soft_bounce', text: 'Soft bounce' },
        { key: 'hard_bounce', text: 'Hard bounce' },
        { key: 'refunded', text: 'Reembolsado' },
        { key: 'error', text: 'Erro' },
        { key: 'canceled', text: 'Cancelado' },
        { key: 'expired', text: 'Expirado' },
      ],
      operators: [
        { key: '=', text: 'Igual a' },
        { key: '>', text: 'Superior a' },
        { key: '<', text: 'Inferior a' },
      ],
      filterBy: null,
      paymentMethod: null,
      transactionStatus: null
    }
  },

  computed: {
    ...mapState({
      filterForm: ({ singleFile }) => singleFile.filterForm,
      equalTo: ({ singleFile }) => singleFile.filterForm.equalTo
    })
  },

  watch: {
    filterBy (value) {
      this.filterForm.filterBy = this.filters.find( filter => filter.key === value )
    },

    paymentMethod (value) {
      this.filterForm.fieldOne = this.paymentMethods.find( paymentMethod => paymentMethod.key === value )
    },

    transactionStatus (value) {
      this.filterForm.fieldOne = this.statuses.find( status => status.key === value )
    },

    equalTo () {
      if (!this.equalTo) {
        this.filterForm.equalTo = { key: '=', text: 'igual a' }
      }
    }
  },

  methods: {
    bindPlugins () {
      $('.ui.search.dropdown').dropdown()
    },

    errorDate (value) {
      if (this.filterBy && this.filterBy === 'period') {
        return checkDate(value)
      }
      return false
    },

    clearQueryField () {
      if (this.filterForm.fieldOne) {
        this.filterForm.fieldOne = ''
      }
      if (this.filterForm.fieldTwo) {
        this.filterForm.fieldTwo = ''
      }
    }
  },

  mounted () {
    this.bindPlugins()
  }

}
</script>
